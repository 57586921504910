<template>
  <BaseDialog
    :show="true"
    title="到期通知設定"
    @close="$emit('close')"
    @cancel="$emit('close')"
    @confirm="onConfirm"
  >
    <div>
      <BaseElForm
        ref="formRef"
        label-position="top"
        :model="formData"
        :rules="formRules"
      >
        <BaseElFormItem prop="expireNotificationBefore">
          <template slot="label">
            <FormItemTooltipLabel
              label="到期通知發送天數設定"
              :tooltipWidth="180"
            >
              系統將於到期日前 n 天的中午 12 點推播 Line 訊息提醒
            </FormItemTooltipLabel>
          </template>

          <BaseElInput v-model="formData.expireNotificationBefore" />
        </BaseElFormItem>
      </BaseElForm>
    </div>
  </BaseDialog>
</template>

<script>
import { defineComponent } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import { useBaseForm } from '@/use/useForm'
import { noEmptyRules, isDigitRules } from '@/validation'

export default defineComponent({
  name: 'MemberLevelExpiredNotifySettingEditModal',
  components: { BaseDialog, FormItemTooltipLabel },
  props: {
    onSubmit: { type: Function, default: () => {} },
  },
  setup (props, { emit }) {
    const { formRef, formData, initFormData, onSubmit, loading } = useBaseForm()

    initFormData({
      expireNotificationBefore: null,
    })

    const formRules = {
      expireNotificationBefore: [noEmptyRules(), isDigitRules()],
    }

    const onConfirm = async () => {
      await onSubmit(props.onSubmit, formData.expireNotificationBefore, {
        emit,
        loading,
      })
    }

    return { formRef, formData, formRules, onConfirm }
  },
})
</script>

<style lang="postcss" scoped></style>
