<template>
  <BaseDialog
    :show="true"
    title="會員級別機制設定"
    width="600px"
    :btn1-loading="loading"
    @close="$emit('close')"
    @cancel="$emit('close')"
    @confirm="onConfirm"
  >
    <div>
      <BaseElForm
        ref="formRef"
        label-position="top"
        :model="formData"
        :rules="formRules"
      >
        <BaseElFormItem prop="autoLevel">
          <template slot="label">
            <FormItemTooltipLabel
              label="新會員加入時，自動成為等級階層 1 的會員"
              :tooltipWidth="180"
            >
              若關閉此設定，需人工將會員的等級調整為等級階層1
            </FormItemTooltipLabel>
          </template>
          <BaseElSwitch
            v-model="formData.autoLevel"
            inactive-text="關閉"
            active-text="開啟"
            @change="onChange"
          />
        </BaseElFormItem>

        <BaseElFormItem
          v-if="!formData.autoLevel"
          prop="nullLevelName"
          label="新會員預設等級名稱"
        >
          <BaseElInput v-model="formData.nullLevelName" />
        </BaseElFormItem>
      </BaseElForm>
    </div>
  </BaseDialog>
</template>

<script>
import { defineComponent, computed } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import { useBaseForm } from '@/use/useForm'
import { noEmptyRules } from '@/validation'

export default defineComponent({
  name: 'MemberLevelExpiredNotifySettingEditModal',
  components: { BaseDialog, FormItemTooltipLabel },
  props: {
    onSubmit: { type: Function, default: () => {} },
    configData: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const { formRef, formData, initFormData, onSubmit, loading, checkForm } = useBaseForm()

    initFormData({
      autoLevel: props.configData.autoLevel,
      nullLevelName: props.configData.nullLevelName || '無會員等級',
    })

    const formRules = computed(() => {
      return {
        autoLevel: [
          ...formData.autoLevel ? [noEmptyRules()] : [],
        ],
        nullLevelName: [
          ...formData.autoLevel ? [] : [noEmptyRules()],
        ],
      }
    })

    const onConfirm = async () => {
      if (!(await checkForm(formRef.value))) return
      await onSubmit(props.onSubmit,
        {
          newAutoLevel: formData.autoLevel,
          newNullLevelName: formData.nullLevelName,
        },
        {
          emit,
          loading,
        },
      )
      emit('close')
    }

    return {
      formRef,
      formData,
      formRules,
      onConfirm,
      loading,
    }
  },
})
</script>

<style lang="postcss" scoped></style>
